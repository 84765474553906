import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: "https://ppg.umkendari.ac.id/api/",
  // baseURL: "http://127.0.0.1:8000/api/",
});

const token = Cookies.get("refreshToken") ?? null;

api.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : "";
api.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

export default api;
